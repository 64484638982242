
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, watchEffect, PropType } from 'vue'

// Composables
import { System } from '@/tracing/system/use-systems'

// Misc
import { splitTypeSystem, SystemName } from '@/models/otel'

export default defineComponent({
  name: 'SystemPicker',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
    systems: {
      type: Array as PropType<System[]>,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const autocomplete = shallowRef()
    const searchInput = shallowRef('')

    const internalSystems = computed(() => {
      const systems = props.systems.slice()

      for (let system of props.value) {
        const index = systems.findIndex((item) => item.system === system)
        if (index === -1) {
          systems.push({
            system,
            count: 0,
            rate: 0,
            errorCount: 0,
            errorRate: 0,
            groupCount: 0,
          })
        }
      }

      return systems
    })

    const filteredSystems = computed(() => {
      if (!searchInput.value) {
        return internalSystems.value
      }
      return fuzzyFilter(internalSystems.value, searchInput.value, { key: 'system' })
    })

    watchEffect(
      () => {
        if (props.loading || props.value.length) {
          return
        }
        if (props.systems.length) {
          ctx.emit('input', props.systems[0].system)
        }
      },
      { flush: 'post' },
    )

    function comma(item: System, index: number): string {
      if (index > 0) {
        return ', ' + item.system
      }
      return item.system
    }

    function toggle(system: string) {
      const allSystems = [SystemName.All, SystemName.SpansAll, SystemName.EventsAll] as string[]

      let activeSystems = props.value.slice()

      const index = activeSystems.indexOf(system)
      if (index >= 0) {
        activeSystems.splice(index, 1)
        if (activeSystems.length) {
          ctx.emit('input', activeSystems)
          return
        }

        if (props.systems.length) {
          ctx.emit('input', [props.systems[0].system])
          return
        }

        ctx.emit('input', [])
        return
      }

      if (allSystems.includes(system)) {
        ctx.emit('input', [system])
        return
      }

      activeSystems = activeSystems.filter((system) => !allSystems.includes(system))

      if (system.endsWith(':all')) {
        const [type] = splitTypeSystem(system)
        activeSystems = activeSystems.filter((system) => !system.startsWith(type + ':'))
      } else if (activeSystems.length) {
        const [type] = splitTypeSystem(system)
        activeSystems = activeSystems.filter((system) => system !== type + ':all')
      }

      activeSystems.push(system)
      ctx.emit('input', activeSystems)
    }

    function toggleOne(system: string) {
      if (props.value.length === 1 && props.value.includes(system)) {
        if (props.systems.length) {
          ctx.emit('input', [props.systems[0].system])
          return
        }

        ctx.emit('input', [])
        return
      }

      ctx.emit('input', [system])
      return
    }

    return {
      autocomplete,
      searchInput,
      filteredSystems,
      comma,
      toggle,
      toggleOne,
    }
  },
})
