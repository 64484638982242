
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useDateRangeFrom, UseDateRange } from '@/use/date-range'
import { joinQuery } from '@/use/uql'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import DashGrid from '@/metrics/DashGrid.vue'

// Misc
import { Dashboard, GridRow, TableRowData } from '@/metrics/types'

export default defineComponent({
  name: 'DashGridForTableRow',
  components: { DateRangePicker, DashGrid },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    gridRows: {
      type: Array as PropType<GridRow[]>,
      required: true,
    },
    gridMetrics: {
      type: Array as PropType<string[]>,
      required: true,
    },
    tableRow: {
      type: Object as PropType<TableRowData>,
      required: true,
    },
    maxWidth: {
      type: [Number, String],
      default: 1900,
    },
  },

  setup(props) {
    const internalDateRange = useDateRangeFrom(props.dateRange)

    const gridQuery = computed(() => {
      const ss = []
      if (props.dashboard.gridQuery) {
        ss.push(props.dashboard.gridQuery)
      }
      if (props.tableRow._query) {
        ss.push(props.tableRow._query)
      }
      return joinQuery(ss)
    })

    return { internalDateRange, gridQuery }
  },
})
