
import { pick } from 'lodash-es'
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useAnnotations } from '@/org/use-annotations'
import { useTitle } from '@vueuse/core'
import { UseDateRange } from '@/use/date-range'
import { useUql, useQueryStore, provideQueryStore } from '@/use/uql'
import { useProject } from '@/org/use-projects'
import { useSystems, addAllSystem } from '@/tracing/system/use-systems'

// Components
import TracingPlaceholder from '@/tracing/TracingPlaceholder.vue'
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import SystemPicker from '@/tracing/system/SystemPicker.vue'
import QuickSpanFilter from '@/tracing/query/QuickSpanFilter.vue'
import SystemQuickMetrics from '@/tracing/system/SystemQuickMetrics.vue'

// Misc
import { isSpanSystem, isErrorSystem, SystemName, AttrKey } from '@/models/otel'
import { DAY } from '@/util/fmt/date'

interface ChosenSystem {
  name: string
  groupCount: number
}

export default defineComponent({
  name: 'Overview',
  components: {
    TracingPlaceholder,
    DateRangePicker,
    SystemPicker,
    QuickSpanFilter,
    SystemQuickMetrics,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
  },

  setup(props) {
    useTitle('Overview')

    const project = useProject()

    const uql = useUql()
    provideQueryStore(useQueryStore(uql))

    useAnnotations(() => {
      return {
        ...props.dateRange.axiosParams(),
      }
    })

    const systems = useSystems(() => {
      return {
        ...props.dateRange.axiosParams(),
        ...uql.axiosParams(),
      }
    })

    const spanSystems = computed(() => {
      const items = systems.items.filter((item) => isSpanSystem(item.system))
      addAllSystem(items, SystemName.SpansAll)
      return items
    })

    const chosenSystems = computed((): ChosenSystem[] => {
      if (props.dateRange.duration > 3 * DAY) {
        return []
      }

      const chosenMap = new Map<string, ChosenSystem>()

      for (let system of systems.items) {
        if (system.isGroup) {
          continue
        }
        if (!isErrorSystem(system.system)) {
          continue
        }

        const found = chosenMap.get(system.system)
        if (found) {
          found.groupCount += system.groupCount
        } else {
          chosenMap.set(system.system, {
            name: system.system,
            groupCount: system.groupCount,
          })
        }
      }

      return Array.from(chosenMap.values())
    })

    return {
      AttrKey,

      uql,
      project,
      systems,
      spanSystems,

      chosenSystems,
      pick,
    }
  },
})
