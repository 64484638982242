
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useMonitorManager } from '@/alerting/use-monitors'

// Misc
import { MetricMonitor } from '@/alerting/types'

export default defineComponent({
  name: 'MonitorMetricFormPanes',
  components: {
    Splitpanes,
    Pane,
  },

  props: {
    monitor: {
      type: Object as PropType<MetricMonitor>,
      required: true,
    },
    maxWidth: {
      type: Number,
      default: 1416,
    },
  },

  setup(props, ctx) {
    const form = shallowRef()
    const isValid = shallowRef(false)

    const monitorMan = useMonitorManager()
    function submit() {
      if (!form.value.validate()) {
        return
      }

      save().then(() => {
        ctx.emit('saved')
      })
    }
    function save() {
      if (props.monitor.id) {
        return monitorMan.updateMetricMonitor(props.monitor)
      }
      return monitorMan.createMetricMonitor(props.monitor)
    }

    return {
      form,
      isValid,

      monitorMan,
      submit,
    }
  },
})
