import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=3dedc2a7&scoped=true&"
import script from "./Layout.vue?vue&type=script&lang=ts&"
export * from "./Layout.vue?vue&type=script&lang=ts&"
import style0 from "./Layout.vue?vue&type=style&index=0&id=3dedc2a7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_vue-template-compiler@2.7.15_webpack@5.80.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dedc2a7",
  null
  
)

export default component.exports