
import { defineComponent, PropType } from 'vue'

// Composables
import { useYamlDashboard } from '@/metrics/use-dashboards'

// Misc
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashboardYamlCard',

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      default: undefined,
    },
  },

  setup() {
    const dash = useYamlDashboard()
    return { dash }
  },
})
