
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { Project } from '@/org/use-projects'
import { UseAchievements } from '@/org/use-achievements'

export default defineComponent({
  name: 'GetStartedMenu',

  props: {
    project: {
      type: Object as PropType<Project>,
      default: undefined,
    },
    achievements: {
      type: Object as PropType<UseAchievements>,
      required: true,
    },
  },

  setup(props) {
    const menu = shallowRef(false)

    return {
      menu,
    }
  },
})
