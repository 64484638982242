
import { defineComponent, shallowRef, computed, watch } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useSyncQueryParams } from '@/use/router'
import { injectForceReload } from '@/use/force-reload'
import { usePager } from '@/use/pager'
import { useFacetedSearch } from '@/use/faceted-search'
import { useAlerts, useAlertSelection, Alert } from '@/alerting/use-alerts'

// Components
import ForceReloadBtn from '@/components/ForceReloadBtn.vue'
import AlertsSidebar from '@/alerting/AlertsSidebar.vue'
import AlertSelection from '@/alerting/AlertSelection.vue'
import AlertOrderPicker from '@/alerting/AlertOrderPicker.vue'
import AlertsTable from '@/alerting/AlertsTable.vue'
import AlertCard from '@/alerting/AlertCard.vue'

export default defineComponent({
  name: 'AlertList',
  components: {
    ForceReloadBtn,
    AlertsSidebar,
    AlertSelection,
    AlertOrderPicker,
    AlertsTable,
    AlertCard,
  },

  setup() {
    useTitle('Alerts')

    const dialog = shallowRef(false)
    const activeAlertId = shallowRef<number>()

    const forceReload = injectForceReload()

    const pager = usePager()
    const facetedSearch = useFacetedSearch()
    const alerts = useAlerts(
      computed(() => {
        const params: Record<string, any> = {
          ...forceReload.params,
          ...facetedSearch.axiosParams(),
        }

        return params
      }),
    )
    const pageAlerts = computed(() => {
      return alerts.items.slice(pager.pos.start, pager.pos.end)
    })

    const selection = useAlertSelection(
      computed(() => {
        return alerts.items
      }),
      pageAlerts,
    )

    useSyncQueryParams({
      fromQuery(queryParams) {
        if (queryParams.empty()) {
          queryParams.setDefault('attrs.alert.status', 'open')
        }

        queryParams.setDefault('sort_by', 'updated_at')
        queryParams.setDefault('sort_desc', true)

        alerts.order.parseQueryParams(queryParams)
        facetedSearch.parseQueryParams(queryParams)
      },
      toQuery() {
        return {
          ...alerts.order.queryParams(),
          ...facetedSearch.queryParams(),
        }
      },
    })

    watch(
      () => alerts.items.length,
      (numItem) => {
        pager.numItem = numItem
      },
    )

    function showAlert(alert: Alert) {
      activeAlertId.value = alert.id
      dialog.value = true
    }

    return {
      dialog,
      activeAlertId,
      showAlert,

      facetedSearch,
      alerts,
      selection,

      pager,
      pageAlerts,
    }
  },
})
