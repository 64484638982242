
import { defineComponent, shallowRef, computed, watch, proxyRefs, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { AxiosParams } from '@/use/axios'
import { useDataSource, Item } from '@/use/datasource'
import { UseUql } from '@/use/uql'

// Components
import Combobox from '@/components/Combobox.vue'
import UqlChip from '@/components/UqlChip.vue'

// Misc
import { requiredRule } from '@/util/validation'
import { quote } from '@/util/string'
import { AttrKey } from '@/models/otel'

interface Column extends Item {
  ordered?: boolean
  searchable?: boolean
}

enum Op {
  Exists = 'exists',
  NotExists = 'not exists',

  Like = 'like',
  NotLike = 'not like',

  Contains = 'contains',
  NotContains = 'not contains',
}

export default defineComponent({
  name: 'WhereFilterMenu',
  components: { Combobox, UqlChip },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute()
    const menu = shallowRef(false)
    const formRef = shallowRef()
    const form = useForm()

    const columnsDs = useDataSource<Column>(
      () => {
        if (!menu.value) {
          return null
        }

        const { projectId } = route.value.params
        return {
          url: `/internal/v1/tracing/${projectId}/attr-keys?with_columns`,
          params: props.axiosParams,
        }
      },
      { suggestSearchInput: true },
    )

    const opItems = computed((): string[] => {
      const ops = []
      ops.push(Op.Contains, Op.NotContains, Op.Like, Op.NotLike)
      ops.push('=', '!=', '<', '<=', '>', '>=')
      ops.push(Op.Exists, Op.NotExists)
      return ops
    })

    const valuesDs = useDataSource(
      () => {
        if (!menu.value || !form.column || !form.column.value) {
          return
        }

        const { projectId } = route.value.params
        return {
          url: `/internal/v1/tracing/${projectId}/attr-values`,
          params: {
            ...props.axiosParams,
            attr_key: form.column.value,
          },
        }
      },
      { suggestSearchInput: true },
    )

    const valuePlaceholder = computed((): string => {
      switch (form.op) {
        case Op.Like:
        case Op.NotLike:
          return '%substring% or %suffix or prefix%'
        case Op.Contains:
        case Op.NotContains:
          return 'substr1|substr2|substr3'
        default:
          return ''
      }
    })

    const valueHint = computed((): string => {
      switch (form.op) {
        case Op.Like:
        case Op.NotLike:
          return '"%" matches zero or more characters'
        case Op.Contains:
        case Op.NotContains:
          return 'Case-insensitive options separated with "|"'
        default:
          return ''
      }
    })

    const valueDisabled = computed((): boolean => {
      switch (form.op) {
        case Op.Exists:
        case Op.NotExists:
          return true
        default:
          return false
      }
    })

    function addFilter() {
      setTimeout(() => {
        if (!form.column || !form.op) {
          return
        }

        const editor = props.uql.createEditor()

        if (valueDisabled.value) {
          editor.add(`where ${form.column.value} ${form.op}`)
        } else {
          const value = form.columnValue?.value ?? ''
          editor.add(`where ${form.column.value} ${form.op} ${quote(value)}`)
        }

        props.uql.commitEdits(editor)

        form.column = undefined
        form.op = ''
        form.columnValue = undefined
        formRef.value.resetValidation()

        menu.value = false
      }, 10)
    }

    watch(valueDisabled, (disabled) => {
      if (disabled) {
        form.columnValue = undefined
      }
    })

    return {
      AttrKey,
      menu,
      form,
      formRef,

      columnsDs,
      opItems,
      valuesDs,
      valuePlaceholder,
      valueHint,
      valueDisabled,

      addFilter,
    }
  },
})

function useForm() {
  const isValid = shallowRef(false)
  const rules = {
    column: [requiredRule],
    op: [requiredRule],
    columnValue: [],
  }

  const column = shallowRef<Column>()
  const op = shallowRef('')
  const columnValue = shallowRef<Item>()

  return proxyRefs({
    isValid,
    rules,

    column,
    op,
    columnValue,
  })
}
