
import { cloneDeep } from 'lodash-es'
import { defineComponent, shallowRef, reactive, computed, proxyRefs, watch, PropType } from 'vue'

// Composables
import { useSyncQueryParams } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { useRoute } from '@/use/router'
import { useDataSource } from '@/use/datasource'
import { useUql, createQueryEditor } from '@/use/uql'
import { useActiveMetrics } from '@/metrics/use-metrics'
import { useTableQuery } from '@/metrics/use-query'

// Components
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import GroupingToggle from '@/metrics/query/GroupingToggle.vue'
import TimeseriesTable from '@/metrics/TimeseriesTable.vue'
import DashTableFormDialog from '@/metrics/DashTableFormDialog.vue'
import DashGridForTableRow from '@/metrics/DashGridForTableRow.vue'
import ApiErrorCard from '@/components/ApiErrorCard.vue'
import GridStackCard from '@/metrics/GridStackCard.vue'
import GridItemAny from '@/metrics/GridItemAny.vue'
import QuickSearch from '@/components/QuickSearch.vue'

// Misc
import { AttrKey } from '@/models/otel'
import { Dashboard, DashKind, GridRow, GridItem, TableRowData } from '@/metrics/types'

export default defineComponent({
  name: 'DashboardTable',
  components: {
    MetricsQueryBuilder,
    GroupingToggle,
    TimeseriesTable,
    DashTableFormDialog,
    DashGridForTableRow,
    ApiErrorCard,
    GridStackCard,
    GridItemAny,
    QuickSearch,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
    tableItems: {
      type: Array as PropType<GridItem[]>,
      required: true,
    },
    gridRows: {
      type: Array as PropType<GridRow[]>,
      required: true,
    },
    gridMetrics: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },

  setup(props, ctx) {
    const route = useRoute()
    const dialog = shallowRef(false)
    const uql = useUql()

    const activeMetrics = useActiveMetrics(computed(() => props.dashboard.tableMetrics))

    const tableQuery = useTableQuery(
      () => {
        if (!props.dashboard.tableQuery || !props.dashboard.tableMetrics.length) {
          return { _: undefined }
        }

        return {
          ...props.dateRange.axiosParams(),
          time_offset: props.dashboard.timeOffset,
          metric: props.dashboard.tableMetrics.map((m) => m.name),
          alias: props.dashboard.tableMetrics.map((m) => m.alias),
          query: uql.query,
          min_interval: props.dashboard.minInterval,
        }
      },
      computed(() => props.dashboard.tableColumnMap),
    )

    useSyncQueryParams({
      fromQuery(queryParams) {
        queryParams.setDefault('query', props.dashboard.tableQuery)

        props.dateRange.parseQueryParams(queryParams)
        tableQuery.order.parseQueryParams(queryParams)
        uql.parseQueryParams(queryParams)
      },
      toQuery() {
        return {
          ...props.dateRange.queryParams(),
          ...tableQuery.order.queryParams(),
          ...uql.queryParams(),
        }
      },
    })

    // Update the query when the dashboard is updated.
    watch(
      () => props.dashboard.tableQuery,
      (tableQuery) => {
        uql.query = tableQuery
      },
      { immediate: true },
    )

    watch(
      () => tableQuery.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    const attrKeysDs = useDataSource(() => {
      if (!props.dashboard.tableMetrics.length) {
        return undefined
      }

      const { projectId } = route.value.params
      return {
        url: `/internal/v1/metrics/${projectId}/attr-keys`,
        params: {
          ...props.dateRange.axiosParams(),
          metric: props.dashboard.tableMetrics.map((m) => m.name),
        },
      }
    })

    const grouping = computed({
      get() {
        return tableQuery.columns.filter((col) => col.isGroup).map((column) => column.name)
      },
      set(grouping: string[]) {
        const editor = createQueryEditor()

        for (let colName of grouping) {
          editor.groupBy(colName)
        }

        for (let part of uql.parts) {
          if (/^group by/i.test(part.query)) {
            continue
          }
          editor.add(part.query)
        }

        uql.commitEdits(editor)
      },
    })

    const tableItem = useTableRowData()

    return {
      AttrKey,
      DashKind,

      dialog,

      uql,
      activeMetrics,
      tableQuery,

      attrKeysDs,
      grouping,

      tableItem,

      cloneDeep,
      reactive,
    }
  },
})

function useTableRowData() {
  const dialog = shallowRef(false)
  const activeItem = shallowRef<TableRowData>()

  const tableListeners = computed(() => {
    return {
      click(item: TableRowData) {
        activeItem.value = item
        dialog.value = true
      },
    }
  })

  return proxyRefs({
    dialog,
    active: activeItem,
    listeners: tableListeners,
  })
}
