
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { useRoute } from '@/use/router'
import { useDataSource } from '@/use/datasource'
import { Filter } from '@/components/facet/types'

// Components
import FacetItemBody from '@/components/facet/FacetItemBody.vue'

export default defineComponent({
  name: 'SpanFacet',
  components: { FacetItemBody },

  props: {
    component: {
      type: String,
      required: true,
    },
    axiosParams: {
      type: undefined as unknown as PropType<Record<string, any> | null>,
      required: true,
    },
    value: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
    attr: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    pinned: {
      type: Boolean,
      required: true,
    },
    pending: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const route = useRoute()
    const expandedInternal = shallowRef(props.expanded)

    const values = useDataSource(() => {
      if (!props.axiosParams) {
        return props.axiosParams
      }
      if (!expandedInternal.value) {
        return undefined
      }
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/${props.component}/${projectId}/attr-values`,
        params: {
          ...props.axiosParams,
          attr_key: props.attr,
        },
        debounce: 500,
      }
    })

    const hasSearch = computed(() => {
      return expandedInternal.value && (values.items.length > 10 || values.searchInput)
    })

    const likeFilter = computed(() => {
      return {
        attr: props.attr,
        op: 'like',
        value: [`%${values.searchInput}%`],
      }
    })

    const notLikeFilter = computed(() => {
      return {
        attr: props.attr,
        op: 'not like',
        value: [`%${values.searchInput}%`],
      }
    })

    function filterString(f: Filter) {
      return `${f.attr} ${f.op} ${f.value}`
    }

    return {
      expandedInternal,
      values,
      hasSearch,

      likeFilter,
      notLikeFilter,
      filterString,
    }
  },
})
