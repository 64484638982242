import { render, staticRenderFns } from "./PageToolbar.vue?vue&type=template&id=07c68e23&"
import script from "./PageToolbar.vue?vue&type=script&lang=ts&"
export * from "./PageToolbar.vue?vue&type=script&lang=ts&"
import style0 from "./PageToolbar.vue?vue&type=style&index=0&id=07c68e23&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.3_vue-template-compiler@2.7.15_webpack@5.80.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports