
import { defineComponent, shallowRef, computed, PropType } from 'vue'
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'

// Composables
import { UseDateRange } from '@/use/date-range'

// Components
import SpanAttrsTable from '@/tracing/SpanAttrsTable.vue'

// Utitlies
import { AttrMap } from '@/models/span'
import { AttrKey, isEventSystem } from '@/models/otel'
import { buildPrefixes, Prefix } from '@/models/key-prefixes'
import { parseJson, prettyPrint } from '@/util/json'

export default defineComponent({
  name: 'SpanAttrs',
  components: { SpanAttrsTable },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    attrs: {
      type: Object as PropType<AttrMap>,
      required: true,
    },
    system: {
      type: String,
      default: '',
    },
    groupId: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const searchInput = shallowRef('')

    const activePrefix = shallowRef<Prefix>()
    const prefixes = computed(() => {
      const keys = []
      for (let key in props.attrs) {
        keys.push(key)
      }
      return buildPrefixes(keys)
    })

    const isEvent = computed((): boolean => {
      return isEventSystem(props.system)
    })

    const axiosParams = computed(() => {
      return {
        ...props.dateRange.axiosParams(),
        system: props.system,
        group_id: props.groupId,
      }
    })

    const attrKeys = computed((): string[] => {
      let keys = Object.keys(props.attrs)

      if (activePrefix.value) {
        keys = activePrefix.value.keys
      }

      if (searchInput.value) {
        keys = fuzzyFilter(keys, searchInput.value)
      }

      keys.sort()

      return keys
    })

    const nestedAttrs = computed(() => {
      return nestAttrs(props.attrs, attrKeys.value)
    })

    return {
      AttrKey,

      searchInput,
      activePrefix,
      prefixes,

      axiosParams,
      isEvent,

      attrKeys,
      nestedAttrs,
      prettyPrint,
    }
  },
})

function nestAttrs(src: Record<string, any>, keys: string[]) {
  const dest: Record<string, any> = {}
  for (let key of keys) {
    if (key.indexOf('.') === -1) {
      dest[key] = src[key]
    } else {
      setValue(dest, key.split('.'), src[key])
    }
  }
  return dest
}

function setValue(dest: Record<string, any>, path: string[], value: any) {
  for (let key of path.slice(0, -1)) {
    if (!(key in dest)) {
      const v = {}
      dest[key] = v
      dest = v
      continue
    }

    let v = dest[key]
    if (v === null || typeof v !== 'object' || Array.isArray(v)) {
      v = { _value: v }
      dest[key] = v
    }
    dest = v
  }

  if (typeof value === 'string') {
    const data = parseJson(value)
    if (data) {
      value = data
    }
  }

  const lastKey = path[path.length - 1]
  dest[lastKey] = value
}
