
import { defineComponent, shallowRef, provide } from 'vue'

// Composables
import { provideForceReload } from '@/use/force-reload'
import { useDateRange } from '@/use/date-range'
import { useUser } from '@/org/use-users'
import { useProject } from '@/org/use-projects'

// Components
import AppBar from '@/components/AppBar.vue'
import UptraceLogoSmall from '@/components/UptraceLogoSmall.vue'
import ProjectPicker from '@/components/ProjectPicker.vue'
import AppSearch from '@/components/AppSearch.vue'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import GlobalConfirm from '@/components/GlobalConfirm.vue'

export default defineComponent({
  name: 'App',
  components: {
    AppBar,
    UptraceLogoSmall,
    ProjectPicker,
    AppSearch,
    GlobalSnackbar,
    GlobalConfirm,
  },

  setup() {
    // Make these global across the app.
    provideForceReload()

    const header = shallowRef(true)
    provide('header', header)

    const footer = shallowRef(true)
    provide('footer', footer)

    const searchVisible = shallowRef(false)

    const dateRange = useDateRange()
    const user = useUser()
    const project = useProject()

    return {
      header,
      footer,
      searchVisible,

      dateRange,
      user,
      project,
    }
  },
})
