
import { defineComponent, shallowRef, ref, computed, watch, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { UseDateRange } from '@/use/date-range'
import { useUql } from '@/use/uql'
import {
  useActiveMetrics,
  defaultMetricAlias,
  defaultMetricQuery,
  ExploredMetric,
} from '@/metrics/use-metrics'
import { useTimeseries, useStyledTimeseries } from '@/metrics/use-query'
import { MetricAlias, LegendType, LegendPlacement, LegendValue } from '@/metrics/types'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import MetricsPicker from '@/metrics/MetricsPicker.vue'
import MetricsQueryBuilder from '@/metrics/query/MetricsQueryBuilder.vue'
import UnitPicker from '@/components/UnitPicker.vue'
import LegendaryChart from '@/metrics/LegendaryChart.vue'

// Misc
import { updateColumnMap, assignColors, emptyMetricColumn, MetricColumn } from '@/metrics/types'

export default defineComponent({
  name: 'ExploreMetric',
  components: { DateRangePicker, MetricsPicker, MetricsQueryBuilder, UnitPicker, LegendaryChart },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    metric: {
      type: Object as PropType<ExploredMetric>,
      required: true,
    },
  },

  setup(props) {
    useTitle('Test Metrics')
    const uql = useUql('')

    const metricAliases = shallowRef<MetricAlias[]>([])
    watch(
      () => props.metric,
      (metric) => {
        const alias = defaultMetricAlias(metric.name)
        metricAliases.value = [
          {
            name: metric.name,
            alias,
          },
        ]
        const column = defaultMetricQuery(metric.instrument, alias)
        uql.query = `${column}`
      },
      { immediate: true },
    )

    const legend = computed(() => {
      return {
        type: LegendType.Table,
        placement: LegendPlacement.Bottom,
        values: [LegendValue.Avg, LegendValue.Last, LegendValue.Min, LegendValue.Max],
        maxLength: 150,
      }
    })

    const activeMetrics = useActiveMetrics(metricAliases)

    const timeseries = useTimeseries(() => {
      if (!metricAliases.value.length || !uql.query) {
        return undefined
      }

      return {
        ...props.dateRange.axiosParams(),
        metric: metricAliases.value.map((m) => m.name),
        alias: metricAliases.value.map((m) => m.alias),
        query: uql.query,
      }
    })

    const columnMap = ref<Record<string, MetricColumn>>({})
    const styledTimeseries = useStyledTimeseries(
      computed(() => timeseries.items),
      columnMap,
      computed(() => ({})),
    )

    watch(
      () => timeseries.query,
      (query) => {
        if (query) {
          uql.setQueryInfo(query)
        }
      },
      { immediate: true },
    )

    watch(
      () => timeseries.columns,
      (columns) => {
        updateColumnMap(columnMap.value, columns, emptyMetricColumn)
        assignColors(columnMap.value, columns)
      },
    )

    return { uql, legend, metricAliases, activeMetrics, columnMap, timeseries, styledTimeseries }
  },
})
