var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.systems.dataHint)?_c('TracingPlaceholder',{attrs:{"date-range":_vm.dateRange,"systems":_vm.systems}}):[_c('PageToolbar',{attrs:{"loading":_vm.systems.loading,"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-toolbar-items',[(_vm.systems.items.length)?_c('SystemPicker',{attrs:{"systems":_vm.spanSystems,"outlined":""},model:{value:(_vm.systems.activeSystems),callback:function ($$v) {_vm.$set(_vm.systems, "activeSystems", $$v)},expression:"systems.activeSystems"}}):_vm._e(),_c('QuickSpanFilter',{staticClass:"ml-2",attrs:{"date-range":_vm.dateRange,"uql":_vm.uql,"name":"env","attr-key":_vm.AttrKey.deploymentEnvironment}}),_c('QuickSpanFilter',{staticClass:"ml-2",attrs:{"date-range":_vm.dateRange,"uql":_vm.uql,"name":"service","attr-key":_vm.AttrKey.serviceName}})],1),_c('v-spacer'),_c('DateRangePicker',{attrs:{"date-range":_vm.dateRange,"range-days":90}})],1),_c('div',{staticClass:"border-bottom"},[_c('div',{staticClass:"grey lighten-5"},[_c('v-container',{staticClass:"mb-2",attrs:{"fluid":""}},[_c('SystemQuickMetrics',{attrs:{"loading":_vm.systems.loading,"systems":_vm.systems.items}})],1),_c('v-container',{staticClass:"pb-0",attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-tabs',{attrs:{"background-color":"transparent"}},[_c('v-tab',{attrs:{"to":{ name: 'SystemOverview', query: _vm.pick(_vm.$route.query, 'system', 'query') }}},[_vm._v("Systems")]),_c('v-tab',{attrs:{"to":{ name: 'ServiceGraph', query: _vm.pick(_vm.$route.query, 'system', 'query') }}},[_vm._v(" Service graph ")]),_vm._l((_vm.chosenSystems),function(system){return _c('v-tab',{key:system.name,attrs:{"to":{
                name: 'SystemGroupList',
                params: { system: system.name },
                query: _vm.pick(_vm.$route.query, 'system', 'query'),
              }}},[_vm._v(" "+_vm._s(system.name)+" ("+_vm._s(system.groupCount)+") ")])}),_c('v-tab',{attrs:{"to":{ name: 'SlowestGroups', query: _vm.pick(_vm.$route.query, 'system', 'query') }}},[_vm._v("Slowest")]),_vm._l((_vm.project.pinnedAttrs),function(attr){return _c('v-tab',{key:attr,attrs:{"to":{
                name: 'AttrOverview',
                params: { attr },
                query: _vm.pick(_vm.$route.query, 'system', 'query'),
              }}},[_vm._v(_vm._s(attr))])})],2)],1)],1)]),_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',[_c('v-col',[_c('router-view',{attrs:{"name":"overview","date-range":_vm.dateRange,"systems":_vm.systems,"uql":_vm.uql}})],1)],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }