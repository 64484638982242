
import { defineComponent, shallowRef, watch, PropType } from 'vue'

// Composables
import { UseDateRange } from '@/use/date-range'
import { Annotation } from '@/org/use-annotations'

// Components
import EventPanelContent from '@/tracing/EventPanelContent.vue'

// Misc
import { AttrKey } from '@/models/otel'
import { SpanEvent } from '@/models/span'

export default defineComponent({
  name: 'EventPanels',
  components: { EventPanelContent },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    events: {
      type: Array as PropType<SpanEvent[]>,
      required: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    annotations: {
      type: Array as PropType<Annotation[]>,
      default: () => [],
    },
  },

  setup(props) {
    const panels = shallowRef<number[]>([])

    watch(
      () => props.events,
      (events) => {
        if (events.length === 1) {
          panels.value = [0]
        } else {
          panels.value = []
        }
      },
      { immediate: true },
    )

    function hasAttrs(event: SpanEvent): boolean {
      return Boolean(event.attrs && Object.keys(event.attrs).length)
    }

    return { AttrKey, panels, hasAttrs }
  },
})
