
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { AxiosParams } from '@/use/axios'
import { useDataSource, Item } from '@/use/datasource'
import { UseUql } from '@/use/uql'

// Components
import Combobox from '@/components/Combobox.vue'
import UqlChip from '@/components/UqlChip.vue'

// Misc
import { AttrKey } from '@/models/otel'
import { requiredRule } from '@/util/validation'

const AGG_FUNCS = [
  { name: 'sum' },
  { name: 'avg' },
  { name: 'min' },
  { name: 'max' },
  { name: 'uniq' },

  { name: 'any' },
  { name: 'anyLast' },
  { name: 'top3' },
  { name: 'top10' },

  { name: 'p50' },
  { name: 'p75' },
  { name: 'p90' },
  { name: 'p95' },
  { name: 'p99' },
]
interface FuncItem {
  text: string
  value: string
}

const aggColumns = [
  { name: AttrKey.spanCount, tooltip: 'Number of spans in a group' },
  { name: AttrKey.spanCountPerMin, tooltip: 'Number of spans per minute in a group' },
  { name: AttrKey.spanErrorCount, tooltip: 'Number of spans with .status_code = "error"' },
  { name: AttrKey.spanErrorRate, tooltip: 'Percent of spans with .status_code = "error"' },
  { name: `p75(${AttrKey.spanDuration})`, tooltip: '75th percentile of .duration' },
  { name: `max(${AttrKey.spanDuration})`, tooltip: 'Max .duration' },
  { name: `uniq(${AttrKey.enduserId})`, tooltip: 'Number of distinct enduser.id' },
]

export default defineComponent({
  name: 'AggFilterMenu',
  components: { Combobox, UqlChip },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { route } = useRouter()

    const menu = shallowRef(false)
    const func = shallowRef<FuncItem>()
    const column = shallowRef<Item>()

    const form = shallowRef()
    const isValid = shallowRef(false)
    const rules = {
      func: [requiredRule],
      column: [isColumnValid],
    }

    const suggestions = useDataSource(
      () => {
        if (!menu.value) {
          return null
        }

        const { projectId } = route.value.params
        return {
          url: `/internal/v1/tracing/${projectId}/attr-keys`,
          params: {
            ...props.axiosParams,
            func: func.value?.value,
          },
        }
      },
      { suggestSearchInput: true },
    )

    const funcItems = computed((): FuncItem[] => {
      const items: FuncItem[] = []
      for (let func of AGG_FUNCS) {
        items.push({ value: func.name, text: func.name + '(...)' })
      }
      return items
    })

    function addFilter() {
      if (!func.value || !column.value) {
        return
      }

      const query = `${func.value.value}(${column.value.value})`
      aggBy(query)

      func.value = undefined
      column.value = undefined
      form.value.resetValidation()
    }

    function aggBy(column: string) {
      const editor = props.uql.createEditor()
      editor.add(column)
      props.uql.commitEdits(editor)

      menu.value = false
    }

    function isColumnValid(s: any) {
      const isValid = Boolean(func.value && s)
      return isValid || 'Column is required'
    }

    return {
      menu,

      form,
      isValid,
      rules,
      suggestions,

      aggColumns,
      func,
      funcItems,
      column,

      addFilter,
      aggBy,
    }
  },
})
