
import { defineComponent, PropType, computed } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { usePercentiles } from '@/tracing/use-percentiles'
import { createQueryEditor } from '@/use/uql'
import { Annotation } from '@/org/use-annotations'

// Components
import EventRateChart from '@/components/EventRateChart.vue'
import NewMonitorMenu from '@/tracing/NewMonitorMenu.vue'
import SpanAttrs from '@/tracing/SpanAttrs.vue'

// Misc
import { AttrKey } from '@/models/otel'
import { SpanEvent } from '@/models/span'

export default defineComponent({
  name: 'EventPanelContent',
  components: { EventRateChart, NewMonitorMenu, SpanAttrs },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
    event: {
      type: Object as PropType<SpanEvent>,
      required: true,
    },
    annotations: {
      type: Array as PropType<Annotation[]>,
      default: () => [],
    },
  },

  setup(props) {
    const { route } = useRouter()

    const percentiles = usePercentiles(() => {
      if (!props.event.groupId) {
        return undefined
      }
      const { projectId } = route.value.params
      return {
        url: `/internal/v1/tracing/${projectId}/percentiles`,
        params: {
          ...props.dateRange.axiosParams(),
          system: props.event.system,
          group_id: props.event.groupId,
        },
      }
    })

    const groupRoute = computed(() => {
      if (!props.event.groupId) {
        return undefined
      }
      return {
        name: 'SpanList',
        query: {
          ...props.dateRange.queryParams(),
          system: props.event.system,
          query: createQueryEditor()
            .exploreAttr(AttrKey.spanGroupId)
            .where(AttrKey.spanGroupId, '=', props.event.groupId)
            .toString(),
        },
      }
    })

    return {
      AttrKey,

      percentiles,
      groupRoute,
    }
  },
})
