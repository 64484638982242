
import { defineComponent, computed, watch, PropType } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useRouterOnly, useRoute } from '@/use/router'
import { UseDateRange } from '@/use/date-range'
import { useAnnotations } from '@/org/use-annotations'
import { useDashboards, useDashboard } from '@/metrics/use-dashboards'

// Components
import DateRangePicker from '@/components/date/DateRangePicker.vue'
import DashPicker from '@/metrics/DashPicker.vue'
import DashboardMenu from '@/metrics/DashboardMenu.vue'
import DashPinBtn from '@/metrics/DashPinBtn.vue'
import DashboardForm from '@/metrics/DashboardForm.vue'
import NewGridItemMenu from '@/metrics/NewGridItemMenu.vue'

// Misc
import { Dashboard, DashKind } from '@/metrics/types'

export default defineComponent({
  name: 'Dashboard',
  components: {
    DateRangePicker,
    DashPicker,
    DashboardMenu,
    DashPinBtn,
    DashboardForm,
    NewGridItemMenu,
  },

  props: {
    dateRange: {
      type: Object as PropType<UseDateRange>,
      required: true,
    },
  },

  setup(props) {
    const router = useRouterOnly()
    const route = useRoute()

    useAnnotations(() => {
      return {
        ...props.dateRange.axiosParams(),
      }
    })

    const dashboards = useDashboards()
    const dashboard = useDashboard()
    useTitle(computed(() => `${dashboard.data?.name ?? 'Dashboard'} | Dashboard`))
    watch(
      () => dashboard.data,
      (data) => {
        if (!dashboard.data) {
          return
        }
        if (route.value.name !== 'DashboardShow') {
          return
        }

        if (dashboard.data.tableMetrics.length && dashboard.data.tableQuery) {
          router.push({ name: 'DashboardTable' })
          return
        }

        if (dashboard.gridRows.length) {
          router.push({ name: 'DashboardGrid' })
          return
        }

        router.push({ name: 'DashboardTable' })
      },
    )

    const dashKind = computed(() => {
      switch (route.value.name) {
        case 'DashboardTable':
          return DashKind.Table
        case 'DashboardGrid':
          return DashKind.Grid
        default:
          return undefined
      }
    })

    function onCreateDashboard(dash: Dashboard) {
      dashboards.reload().then(() => {
        router.replace({ name: 'DashboardShow', params: { dashId: String(dash.id) } })
      })
    }

    function onPinDash() {
      dashboards.reload()
      dashboard.reload()
    }

    return {
      dashboards,
      dashboard,
      dashKind,

      onCreateDashboard,
      onPinDash,
    }
  },
})
