
import { cloneDeep } from 'lodash-es'
import { defineComponent, shallowRef, reactive, PropType } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'
import { useDashboardManager } from '@/metrics/use-dashboards'

// Components
import DashboardForm from '@/metrics/DashboardForm.vue'
import DashboardYamlForm from '@/metrics/DashboardYamlForm.vue'
import DashboardYamlCard from '@/metrics/DashboardYamlCard.vue'

// Misc
import { Dashboard } from '@/metrics/types'

export default defineComponent({
  name: 'DashboardMenu',
  components: { DashboardForm, DashboardYamlForm, DashboardYamlCard },

  props: {
    dashboard: {
      type: Object as PropType<Dashboard>,
      required: true,
    },
  },

  setup(props, ctx) {
    const confirm = useConfirm()

    const menu = shallowRef(false)
    const newDialog = shallowRef(false)
    const newYamlDialog = shallowRef(false)
    const yamlDialog = shallowRef(false)
    const editDialog = shallowRef(false)
    function closeDialog() {
      editDialog.value = false
      newDialog.value = false
      newYamlDialog.value = false
      yamlDialog.value = false
      menu.value = false
    }

    const dashMan = useDashboardManager()

    function cloneDashboard() {
      dashMan.clone(props.dashboard).then((dash) => {
        ctx.emit('created', dash)
      })
    }

    function resetDashboard() {
      dashMan.reset(props.dashboard).then(() => {
        ctx.emit('updated')
      })
    }

    function deleteDashboard() {
      confirm
        .open('Delete', `Do you really want to delete "${props.dashboard.name}" dashboard?`)
        .then(() => {
          dashMan.delete(props.dashboard).then(() => {
            ctx.emit('deleted')
          })
        })
        .catch(() => {})
    }

    return {
      menu,
      newDialog,
      newYamlDialog,
      yamlDialog,
      editDialog,
      closeDialog,

      dashMan,
      cloneDashboard,
      resetDashboard,
      deleteDashboard,

      cloneDeep,
      reactive,
    }
  },
})
