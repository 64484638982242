
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useRouter } from '@/use/router'
import { AxiosParams } from '@/use/axios'
import { UseUql } from '@/use/uql'
import { useDataSource } from '@/use/datasource'

// Components
import SearchableList from '@/components/SearchableList.vue'

// Misc
import { truncateMiddle } from '@/util/string'

export default defineComponent({
  name: 'AttrFilterMenu',
  components: { SearchableList },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    axiosParams: {
      type: Object as PropType<AxiosParams>,
      required: true,
    },
    attrKey: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const { route } = useRouter()
    const menu = shallowRef(false)

    const suggestions = useDataSource(() => {
      if (!menu.value) {
        return null
      }

      const { projectId } = route.value.params
      return {
        url: `/internal/v1/tracing/${projectId}/attr-values`,
        params: {
          ...props.axiosParams,
          attr_key: props.attrKey,
        },
      }
    })

    function addFilter(attrValue: string, op: string) {
      const editor = props.uql.createEditor()
      editor.where(props.attrKey, op, attrValue)
      props.uql.commitEdits(editor)

      menu.value = false
      ctx.emit('change')
    }

    return { menu, suggestions, addFilter, truncateMiddle }
  },
})
