
import { defineComponent } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useMetrics } from '@/metrics/use-metrics'
import { useMonitors } from '@/alerting/use-monitors'

// Components
import ForceReloadBtn from '@/components/ForceReloadBtn.vue'
import MonitorNewMenu from '@/alerting/MonitorNewMenu.vue'
import MonitorsTable from '@/alerting/MonitorsTable.vue'
import MonitorStateCounts from '@/alerting/MonitorStateCounts.vue'

export default defineComponent({
  name: 'MonitorList',
  components: {
    ForceReloadBtn,
    MonitorNewMenu,
    MonitorsTable,
    MonitorStateCounts,
  },

  setup() {
    useTitle('Monitors')

    const metrics = useMetrics()
    const monitors = useMonitors()

    return {
      metrics,
      monitors,
    }
  },
})
