
import { defineComponent, shallowRef, computed, watch, proxyRefs, PropType } from 'vue'

// Composables
import { createQueryPart, QueryPart, UseUql } from '@/use/uql'

// Components
import UptraceQueryChip from '@/components/UptraceQueryChip.vue'

export default defineComponent({
  name: 'UptraceQuery',
  components: { UptraceQueryChip },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const query = shallowRef('')

    watch(
      () => props.uql.query,
      (s) => {
        query.value = s
      },
      { immediate: true },
    )

    function apply() {
      exitRawMode(true)
    }

    function cancel() {
      exitRawMode(false)
    }

    function exitRawMode(save: boolean) {
      props.uql.rawMode = false
      if (save) {
        props.uql.query = query.value
      }
    }

    return {
      query,

      partEditor: usePartEditor(props.uql),
      apply,
      cancel,
    }
  },
})

function usePartEditor(uql: UseUql) {
  const partId = shallowRef<number>()
  const partQuery = shallowRef('')

  const editing = computed(() => {
    return partId.value !== undefined
  })

  function addPart() {
    const part = createQueryPart()
    uql.addPart(part)

    startEditing(part)
  }

  function startEditing(part: QueryPart) {
    partId.value = part.id
    partQuery.value = part.query
  }

  function applyEdits(part: QueryPart) {
    if (partQuery.value !== part.query) {
      part.error = ''
    }
    part.query = partQuery.value

    cancelEdits()
  }

  function cancelEdits() {
    partId.value = undefined
    partQuery.value = ''
    uql.cleanup()
  }

  return proxyRefs({
    partId: partId,
    query: partQuery,
    editing,

    add: addPart,
    startEditing,
    applyEdits,
    cancelEdits,
  })
}
